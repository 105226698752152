html {
    height: 100%;
    width: 100%;
}

html,
body,
body > div {
    margin: 0;
}

.app-container {
    min-width: 100vw;
    min-height: 100vh;
}

.container {
    width: 100%;
    display: flex;
}

.sidebar {
    width: 280px;
    max-width: 280px;
    overflow-x: hidden;
    background-color: #313c51;
    display: flex;
}

.sidebar-open {
    transition: max-width 0.2s ease-in-out;
}

.content {
    flex: 1;
}
