input[type='checkbox'] {
    position: relative;
    margin-right: 15px;
    margin-left: 5px;
    cursor: pointer;
    width: 20px;
}
input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #a6a6a6;
    border-radius: 2px;
    background-color: white;
}
input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid rgb(43, 144, 213);
    border-radius: 2px;
    background-color: rgb(43, 144, 213);
}
