.pageContainer {
    background-color: #ededed;
    margin: 0;
    padding: 22px 20px 20px 16px;
    overflow: visible;
    position: relative;
}

.title {
    height: 2em;
    margin-top: 0;
    margin-bottom: 2rem;
    padding-top: 0.5em;
    padding-left: 1em;
    background-color: #f1f3f6;
}

h2 {
    font-size: 1.2em;
}

.majorHeading {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 30px;
    color: #5e5e5e;
}

.checkboxLabel {
    font-weight: normal;
    margin-bottom: 2rem;
}

.textInput {
    width: 100%;
    height: 26px;
    line-height: 26px;
    padding: 4px 10px;
    color: #5e5e5e;
    font-size: 14px;
    background-color: hsla(0, 0%, 100%, 0.01);
    background-image: none;
    border: 1px solid #cccccc;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textInput:focus {
    border: 1px solid #e7e7e7 !important;
}

.checkboxLabel {
    margin-left: 5px;
    transform: translateY(2px);
    font-size: 18px;
}

.tile {
    background-color: white;
    padding: 20px;
    margin: 0 auto;
    width: 700px;
    margin-bottom: 20px;
    overflow: auto;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
