.container {
    width: 90%;
    height: 90%;
    overflow: hidden;
    min-height: 20vh;
    background-color: rgba(250, 250, 250, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fa {
    margin-bottom: 2rem;
}

.outer {
    position: relative;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.inner {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}
