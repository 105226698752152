.profile div {
    float: left;
    font-size: 16px;
    font-weight: normal;
    height: 46px;
    line-height: 46px;
    padding: 0 20px;
    border-right: 1px solid #c1c1c1;
    cursor: pointer;
}

.profile div:first-child {
    padding-left: 16px !important;
}

.active {
    background-color: #d8dadc;
    font-weight: bold !important;
    cursor: default !important;
}

.disable {
    display: none !important;
}
