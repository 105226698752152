.container {
    display: flex;
    max-width: 100%;
    align-content: stretch;
    align-items: center;
    flex-flow: row nowrap;
    margin-bottom: 0;
    margin-top: 0;
}

.container > div {
    max-width: inherit;
    margin: 0;
}

.fixed {
    align-self: center;
    flex: 0 0 auto;
    overflow: hidden;
}

.fixed > img {
    width: auto;
    max-height: 60px;
}

.fill {
    align-self: center;
    flex: 2 1 auto;
}

.childrenContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;
    overflow: hidden;
    height: 47px;
}

.childrenContainer > div {
    margin: auto 10px auto 10px;
    flex: 0 0 auto;
}

.childrenContainer > :global(div.h1),
.childrenContainer > h1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.userManagementTitle {
    margin-top: 0;
    font-size: 25px;
    line-height: 47px;
    font-weight: bold;
    padding-bottom: 6px;
    padding-left: 16px;
}
