/* File required to override css rules in devextreme and antd */

.dx-datagrid .dx-row > td {
    padding: 5px 11px !important;
    font-size: 12px !important;
    line-height: 14px !important;
}

.dx-datagrid-header-panel {
    background-color: #fff !important;
}

.ant-table.ant-table-small {
    font-size: 13px !important;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
    padding: 3px 8px !important;
}