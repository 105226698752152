.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 600px;
}

.modal {
    height: 549px;
    width: 760px;
    background-color: white;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modal-row-large {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin: 0 2% 0 2%;
}

.modal-row-small {
    display: flex;
    flex-direction: row;
    height: 10%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 2% 0 2%;
    padding-bottom: 20px;
}

/*These are the two columns in the bottom row*/
.small-row-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
    margin-right: -9px;
    display: flex;
    flex-direction: row;
    /*padding-bottom: 50px;*/
}

/*These are the buttons in the bottom row, and the keyword search */
.small-row-item {
    margin: 20px;
}

.cancel {
    cursor: pointer;
    height: 15px;
    width: 71px;
    color: #4d4d4d;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
}

.cancel:hover {
    text-decoration: underline;
}

.large-row-item {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 45%;
    margin: auto;
}

.filter-section-row {
    display: flex;
    margin-bottom: 20px;
    height: 52px;
}

.filter-section-row-item {
    margin-right: 10px;
    width: 138px;
}

/*TODO (COLBY): put relevant search bar styles in inlineform.css*/
.search-bar-input {
    height: 37px;
    width: 138px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin: 10px;
    margin-top: 0px;
}

.search-bar-title {
    height: 12px;
    width: 103px;
    color: #808080;
    font-family: Lato;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    margin-top: 7px;
    margin-bottom: 1px;
}

.filter-section-title {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    color: #4c4c4c;
    padding-bottom: 15px;
    letter-spacing: 1px;
    line-height: 19px;
}

.large-input {
    height: 37px;
    width: 138px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 11px 26px 11px;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 20px;
}

.input-label {
    line-height: 14px;
    height: 14px;
    width: auto;
    min-width: 138px;
    color: #808080;
    font-family: Lato;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 3px;
}

.input-error {
    border: 1px solid #df0000;
}

.error {
    float: right;
    color: #df0000;
}

.disabled-input-label {
    height: 12px;
    width: 138px;
    min-width: 138px;
    color: lightgrey;
    font-family: Lato;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    margin-bottom: 3px;
}

.dual-input {
    width: 90px;
    height: 37px;
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    text-align: center;
    border: 1px solid #cccccc;
}

.calendar-input {
    height: 37px;
    width: 131px;
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    padding: 17px 10px 16px 10px;
    border: 1px solid #cccccc;
}

.disabled-calendar-input {
    height: 37px;
    width: 131px;
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    padding: 17px 10px 16px 10px;
    border: 1px solid #cccccc;
    background-color: #ebebeb;
}

.calendar-icon {
    font-size: 24px;
    padding-left: 23px;
    padding-bottom: 9px;
    padding-top: 1px;
    color: grey;
}
